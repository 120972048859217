@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Satoshi", sans-serif;
  scroll-behavior: smooth;
}

@import "~swiper/css";
@import "~swiper/css/effect-fade";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";

@import "~aos/dist/aos.css";

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 30px;
  border-radius: 5px;
  transition: 0.4s;
  background-color: white;
}

.swiper-wrapper {
  align-items: center !important;
}

.navlink.active {
  border-bottom: 2px solid white;
}

.productLink.active {
  font-weight: 700 !important;
}
